import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import FavoriteCompare from "components/FavoriteCompare"

import { useLocale } from "context/locale"

import LogoSkyTrust from "assets/images/logo-sky-trust.svg"
import LogoStacjaKopernik from "assets/images/logo-stacja-kopernik.svg"
import LogoRezydencjaSikorskiego from "assets/images/logo-rezydencja-sikorskiego.svg"
import LogoPanoramaStruga from "assets/images/logo-panorama-struga.svg"
import LogoRezydencjaCentrum from "assets/images/logo-rezydencja-centrum.svg"
import LogoRezydencjaSwierkowa from "assets/images/logo-rezydencja-swierkowa.svg"
import LogoKrakowskaRogatka from "assets/images/logo-krakowska-rogatka.svg"
import LogoPlazaCity from "assets/images/logo-plaza-city-horizontal.svg"
import LogoUltradom from "assets/images/logo-ultradom.svg"

const ApartmentTile = ({ item, comparison, variant }) => {
  const { t, lang } = useLocale()
  const data = item?.acfApartment || item?.acfOffice

  return (
    <Link
      to={
        data?.status !== "sold"
          ? `${item?.uri}${lang === "en" ? "?lang=en" : ""}`
          : "/"
      }
      className={`apartment-tile ${data.status}`}
    >
      <div className="apartment-tile__top">
        <div>
          <strong>
            {item.acfApartment.investment === "stacja_kopernik" ||
            item.acfApartment.investment === "plaza_city"
              ? t("Częstochowa")
              : item.acfApartment.investment === "rezydencja_centrum"
              ? t("Gliwice")
              : item.acfApartment.investment === "rezydencja_swierkowa" ||
                item.acfApartment.investment === "ultradom"
              ? t("Radom")
              : t("Kielce")}
          </strong>
          {data.investment === "sky_trust" ? (
            <img src={LogoSkyTrust} alt="" />
          ) : data.investment === "sky_trust_offices" ? (
            <img src={LogoSkyTrust} alt="" />
          ) : item.acfApartment.investment === "stacja_kopernik" ? (
            <img src={LogoStacjaKopernik} alt="" />
          ) : item.acfApartment.investment === "rezydencja_sikorskiego" ? (
            <img src={LogoRezydencjaSikorskiego} alt="" />
          ) : item.acfApartment.investment === "panorama_struga" ? (
            <img src={LogoPanoramaStruga} alt="" />
          ) : item.acfApartment.investment === "rezydencja_centrum" ? (
            <img src={LogoRezydencjaCentrum} alt="" />
          ) : item.acfApartment.investment === "rezydencja_swierkowa" ? (
            <img src={LogoRezydencjaSwierkowa} alt="" />
          ) : item.acfApartment.investment === "krakowska_rogatka" ? (
            <img src={LogoKrakowskaRogatka} alt="" />
          ) : item.acfApartment.investment === "plaza_city" ? (
            <img src={LogoPlazaCity} alt="" />
          ) : item.acfApartment.investment === "ultradom" ? (
            <img src={LogoUltradom} alt="" />
          ) : (
            ""
          )}
        </div>
        {data?.status !== "sold" && <FavoriteCompare id={item.id} />}
      </div>
      <div className="apartment-tile__image">
        {variant === "image3d" ? (
          <img src={data?.visualization3d?.sourceUrl} alt="" />
        ) : (
          <img src={data?.visualization2d?.sourceUrl} alt="" />
        )}
      </div>
      {!comparison && (
        <div className="apartment-tile__list">
          <p>
            {t("Numer")}: <strong>{item?.title}</strong>
          </p>
          {data?.roomsnumber && (
            <p>
              {t("Pokoje")}: <strong>{data?.roomsnumber}</strong>
            </p>
          )}
          <p>
            {t("Piętro")}: <strong>{data?.floor ? data?.floor : 0}</strong>
          </p>
          <p>
            {t("Status")}:{" "}
            <strong className={data.status}>
              {data.status === "available"
                ? t("wolne")
                : data.status === "reserved"
                ? t("zarezerwowane")
                : t("sprzedane")}
            </strong>
          </p>
        </div>
      )}
      <div className="apartment-tile__bottom">
        {comparison ? (
          <h3>{item?.title}</h3>
        ) : (
          <h3>
            {data?.area?.toString().replace(".", ",")} m<sup>2</sup>
          </h3>
        )}
      </div>
    </Link>
  )
}

export default ApartmentTile
